import Vue from "vue";
import Vuex from "vuex"
import jwt_decode from "jwt-decode";
import {
    userLogin,
    getPreference
} from '../../utils/apiFunctions';

Vue.use(Vuex);

const state = () => ({
    accessToken: "",
    refreshToken: "",
    loggedUserName: "",
    preference: {},
    loginStatus: false,
    user: {
        userID: "",
        email: "",
    },
    // assessmenWindow:"",
    username: "",
    assessment: {},
    recruitment: {},
    candidate: {},
    training: {},
    collegeDashboard: {},
    candidateRedirect: {},
    paneluuid: null,
    loginData: {},
    userData: {},
    trainingRedirect: {},
    pageredirect: null
});

const getters = {
    getUserData: (state) => state.userData,
    getPageRedirect: (state) => state.pageredirect,
    getCandidateRedirect: (state) => state.candidateRedirect,
    getTrainingRedirect: (state) => state.trainingRedirect,
    getPanelId: (state) => state.paneluuid,
    getUserDetail: (state) => state.user,
    getPreference: (state) => state.preference,
    getUserName: (state) => state.loggedUserName,
    getLoginStatus: (state) => state.loginStatus,
    getLoginData: (state) => state.loginData,
    getAccessToken: (state) => state.accessToken,
    getRefreshToken: (state) => state.refreshToken,
    getRecruitment: (state) => state.recruitment,
    getAssessment: (state) => state.assessment,
    getCandidate: (state) => state.candidate,
    getTraining: (state) => state.training,
    getCollege: (state) => state.collegeDashboard,
}

const actions = {
    async getPreferences(state, data) {
        try {
            const response = await getPreference();
            if (response.status == 200) {
                state.commit('setPreference', response.data.data);
            }
            state.dispatch('checkCookie', data);
        } catch (error) {
            state.dispatch('checkCookie', data);
            console.log(error)
        }
    },
    login(state, userInfo) {
        return new Promise((resolve, reject) => {
            userLogin(userInfo.keycloak, {
                "content-type": "application/x-www-form-urlencoded;charset=utf-8",
            })
                .then((response) => {
                    const data = response.data;
                    if (data.access_token) {
                        state.commit("setToken", data.access_token);
                        state.commit("setRefreshToken", data.refresh_token);
                        resolve(response);
                    } else {
                        reject(data);
                    }
                    return response;
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    saveAccess(state, data) {
        // document.cookie = `refreshToken=${state.getters.getRefreshToken};expires=Wed, 15 Jan 2025 12:00:00 UTC;path=/;`;
        // document.cookie = `accessToken=${state.getters.getAccessToken};expires=Wed, 15 Jan 2025 12:00:00 UTC;path=/;`;
        document.cookie = `refreshToken=${state.getters.getRefreshToken};expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;domain=simplifymycareer.com;`;
        document.cookie = `accessToken=${state.getters.getAccessToken};expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;domain=simplifymycareer.com;`;
        const { details, flag, loginData, mobaccess } = data
        state.commit('setLoginData', loginData);
        for (let i in details) {
            if (details[i].project === 'assessment') {
                state.commit('setAssessment', details[i]);
            } else if (details[i].project === 'recruitment') {
                state.commit('setRecruitment', details[i]);
            } else if (details[i].project === 'candidate') {
                state.commit('setCandidate', details[i])
            } else if (details[i].project === 'collegeDashboard') {
                state.commit('setCollege', details[i])
            } else if (details[i].project === 'training') {
                state.commit('setTraining', details[i]);
            }
        }
        state.dispatch('getPreferences', data)

    },
    goToPage(state, value) {
        let project = typeof value === 'object' ? value.value : value;
        let uuid = typeof value === 'object' ? value.panelId : null;
        if (Object.keys(state.getters.getTrainingRedirect).length == 2) {
            // window.location.replace(`http://localhost:8082/training/${state.getters.getTrainingRedirect.redirect}/${state.getters.getTrainingRedirect.courseID}`);
            window.location.replace(`https://candidate.simplifymycareer.com/training/${state.getters.getTrainingRedirect.redirect}/${state.getters.getTrainingRedirect.courseID}`);
        }
        else if (project === 'candidate') {
            // window.location.replace("http://localhost:8083?from=candidate");
            window.location.replace("https://candidate.simplifymycareer.com?from=candidate");
        }
        else if (project === 'recruitment') {
            if (uuid) {
                console.log('panel logged in');
                // window.location.replace(`http://localhost:8081/jobs/paneldetails/?uuid=${uuid}&panel=true`);
                window.location.replace(`https://recruitment.simplifymycareer.com/jobs/paneldetails/?uuid=${panelId}&panel=true`);
            }
            else {
                // window.location.replace("http://localhost:8081");
                window.location.replace("https://recruitment.simplifymycareer.com");
            }
        }
        else if (project === 'assessment') {
            // window.location.replace("http://localhost:8082");
            window.location.replace("https://judge.simplifymycareer.com");
        }
        else if (project === 'training') {
            // window.location.replace("http://localhost:8082");
            window.location.replace("https://trainingadmin.simplifymycareer.com");
        } else if (project === 'collegeDashboard') {
            // window.location.replace("http://localhost:8085");
            window.location.replace("https://college.simplifymycareer.com?from=login");
        }
    },
    checkCookie(state, data) {
        state.commit('setLoginStatus', true);
        state.commit('setUserName', jwt_decode(state.getters.getAccessToken).given_name);
        document.cookie = `loginData=${JSON.stringify(state.getters.getLoginData)};expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;domain=simplifymycareer.com;`;
        if (Object.keys(state.getters.getAssessment).length > 0) {
            document.cookie = `assessment=${JSON.stringify(state.getters.getAssessment)};expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;domain=simplifymycareer.com;`;
            // document.cookie = `assessment=${JSON.stringify(state.getters.getAssessment)};expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;`;
        } else {
            document.cookie = `assessment=no-access;expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;domain=simplifymycareer.com;`;
            // document.cookie = `assessment=no-access;expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;`;
        }
        if (Object.keys(state.getters.getRecruitment).length > 0) {
            document.cookie = `recruitment=${JSON.stringify(state.getters.getRecruitment)};expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;domain=simplifymycareer.com;`;
            // document.cookie = `recruitment=${JSON.stringify(state.getters.getRecruitment)};expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;`;
        } else if (state.getters.getLoginData.type == 'panel') {
            document.cookie = `recruitment=ExternalPanelist;expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;domain=simplifymycareer.com;`;
            // document.cookie = `recruitment=ExternalPanelist;expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;`;
        }
        else {
            document.cookie = `recruitment=no-access;expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;domain=simplifymycareer.com;`;
            // document.cookie = `recruitment=no-access;expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;`;
        }
        if (Object.keys(state.getters.getCandidate).length > 0) {
            document.cookie = `candidate=${JSON.stringify(state.getters.getCandidate)};expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;domain=simplifymycareer.com;`;
            // document.cookie = `candidate=${JSON.stringify(state.getters.getCandidate)};expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;`;
        } else {
            document.cookie = `candidate=no-access;expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;domain=simplifymycareer.com;`;
            // document.cookie = `candidate=no-access;expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;`;
        }
        if (Object.keys(state.getters.getTraining).length > 0) {
            document.cookie = `training=${JSON.stringify(state.getters.getTraining)};expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;domain=simplifymycareer.com;`;
            // document.cookie = `training=${JSON.stringify(state.getters.getTraining)};expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;`;
        } else {
            // document.cookie = `training=no-access;expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;`;
            document.cookie = `training=no-access;expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;domain=simplifymycareer.com;`;
        }
        const college = state.getters.getCollege || {};
        if (Object.keys(college).length > 0) {
            document.cookie = `collegeDashboard=${JSON.stringify(college)};expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;domain=simplifymycareer.com;`;
            // document.cookie = `collegeDashboard=${JSON.stringify(college)};expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;`;
        } else {
            // document.cookie = `collegeDashboard=no-access;expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;`;
            document.cookie = `collegeDashboard=no-access;expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;domain=simplifymycareer.com;`;
        }
        // if(flag!=='no-redirect'){
        // document.cookie=`refreshToken=${state.getters.getRefreshToken};expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;domain=simplifymycareer.com;`;
        // document.cookie=`accessToken=${state.getters.getAccessToken};expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;domain=simplifymycareer.com;`;
        // document.cookie = `loginData=${JSON.stringify(state.getters.getLoginData)};expires=Wed, 14 Jan 2026 12:00:00 UTC;path=/;`;
        if (Object.keys(state.getters.getTrainingRedirect).length == 2) {
            // window.location.replace(`http://localhost:8082/training/${state.getters.getTrainingRedirect.redirect}/${state.getters.getTrainingRedirect.courseID}`);
            window.location.replace(`https://candidate.simplifymycareer.com/training/${state.getters.getTrainingRedirect.redirect}/${state.getters.getTrainingRedirect.courseID}`);
        }
        else if (state.getters.getLoginData.type == 'panel' && (Object.keys(state.getters.getRecruitment).length > 0 || Object.keys(state.getters.getRecruitment).length == 0)) {
            // window.location.replace(`http://localhost:8083/jobs/paneldetails/?uuid=${state.getters.getPanelId}&panel=true`);
            window.location.replace(`https://recruitment.simplifymycareer.com/jobs/paneldetails/?uuid=${state.getters.getPanelId}&panel=true`);
        }
        else if (data.flag === 'Panelist' && Object.keys(state.getters.getRecruitment).length > 0) {
            // window.location.replace("http://localhost:8083?from=recruitment&type=admin");
            window.location.replace("https://recruitment.simplifymycareer.com");
        }
        else if (data.flag === 'assessment' && Object.keys(state.getters.getAssessment).length > 0) {

            // window.location.replace("http://localhost:8082?from=assessment");
            window.location.replace("https://judge.simplifymycareer.com?from=assessment");
            // state.commit('setAssessmentWindow',data);
            // setTimeout(()=>{
            //     data.close();
            // },5000);
        } else if (data.flag === 'recruitment' && Object.keys(state.getters.getRecruitment).length > 0) {
            // window.location.replace("http://localhost:8083?from=recruitment&type=admin");
            window.location.replace("https://recruitment.simplifymycareer.com");
        }
        else if (data.flag === "candidate") {
            if (state.getters.getPageRedirect) {
                // window.location.replace(`http://localhost:8083${state.getters.getPageRedirect}`);
                window.location.replace(`https://candidate.simplifymycareer.com${state.getters.getPageRedirect}`);
            }
            else
                if (Object.keys(state.getters.getCandidateRedirect).length == 2) {
                    // window.location.replace(`http://localhost:8084/${state.getters.getCandidateRedirect.to}?jobID=${state.getters.getCandidateRedirect.jobID}`);
                    window.location.replace(`https://candidate.simplifymycareer.com/${state.getters.getCandidateRedirect.to}?jobID=${state.getters.getCandidateRedirect.jobID}`);
                }
                else if (Object.keys(state.getters.getCandidateRedirect).length == 1) {
                    // window.location.replace(`http://localhost:8084/${state.getters.getCandidateRedirect.to}`);
                    window.location.replace(`https://candidate.simplifymycareer.com/${state.getters.getCandidateRedirect.to}`);
                }
                else if (data.mobaccess) {
                    if (!data.mobaccess.includes(state.getters.getPreference.defaultModule)) {
                        if (data.mobaccess.includes('candidate')) {
                            // window.location.replace("http://localhost:8084?from=recruitment&type=candidate");
                            window.location.replace("https://candidate.simplifymycareer.com?from=candidate");
                        }
                        else if (data.mobaccess.includes('recruitment')) {
                            // window.location.replace("http://localhost:8083?from=admin&type=admin");
                            window.location.replace("https://recruitment.simplifymycareer.com");
                        }
                    }
                    else {
                        if (state.getters.getPreference.defaultModule == 'candidate') {
                            // window.location.replace("http://localhost:8084?from=recruitment&type=candidate");
                            window.location.replace("https://candidate.simplifymycareer.com?from=candidate");
                        }
                        else if (state.getters.getPreference.defaultModule == 'recruitment') {
                            // window.location.replace("http://localhost:8083?from=admin&type=admin");
                            window.location.replace("https://recruitment.simplifymycareer.com");
                        }
                    }

                }
                else {
                    // window.location.replace("http://localhost:8084?from=recruitment&type=candidate");
                    window.location.replace("https://candidate.simplifymycareer.com?from=candidate");
                }

            // console.log("Candidate");
        }
        else if (data.flag === "builder" && Object.keys(state.getters.getCandidate).length > 0) {
            // window.location.replace("http://localhost:8085");
            window.location.replace("https://builder.simplifymycareer.com/");
            // console.log("Candidate");
        }
        else if (data.flag === "training" && Object.keys(state.getters.getTraining).length > 0) {
            // window.location.replace("http://localhost:8082");
            window.location.replace("https://trainingadmin.simplifymycareer.com");
        }
        else if (data.flag === "collegeDashboard" && Object.keys(state.getters.getCollege).length > 0) {
            // window.location.replace("http://localhost:8085");
            window.location.replace("https://college.simplifymycareer.com?from=login");
        }
        else if (data.flag === "admin") {
            if (Object.keys(state.getters.getPreference).length > 0 && state.getters.getPreference.defaultModule != null && state.getters.getPreference.defaultModule != 'candidate') {
                if (state.getters.getPreference.defaultModule == 'assessment') {
                    // window.location.replace("http://localhost:8082?from=admin&type=admin");
                    window.location.replace("https://judge.simplifymycareer.com?from=admin&type=admin");
                }
                else if (state.getters.getPreference.defaultModule == 'recruitment') {
                    //    window.location.replace("http://localhost:8083?from=admin&type=admin");
                    window.location.replace("https://recruitment.simplifymycareer.com");
                }
                else if (state.getters.getPreference.defaultModule == 'collegeDashboard') {
                    // window.location.replace("http://localhost:8085?from=admin&type=admin");
                    window.location.replace("https://college.simplifymycareer.com?from=login");
                }
            }
            else {
                if (Object.keys(state.getters.getAssessment).length > 0) {
                    // window.location.replace("http://localhost:8082?from=admin&type=admin"); 
                    window.location.replace("https://judge.simplifymycareer.com?from=admin&type=admin");
                }
                else if (Object.keys(state.getters.getRecruitment).length > 0) {
                    // window.location.replace("http://localhost:8083?from=admin&type=admin");
                    window.location.replace("https://recruitment.simplifymycareer.com");
                }
                else if (Object.keys(state.getters.getCollege).length > 0) {
                    // window.location.replace("http://localhost:8085?from=admin&type=admin");
                    window.location.replace("https://college.simplifymycareer.com?from=login");
                }
                else if (Object.keys(state.getters.getTraining).length > 0) {
                    // window.location.replace("http://localhost:8082");
                    window.location.replace("https://trainingadmin.simplifymycareer.com");
                }
                else if (Object.keys(state.getters.getCandidate).length > 0) {
                    // window.location.replace("http://localhost:8084?from=admin&type=candidate");
                    window.location.replace("https://candidate.simplifymycareer.com?from=candidate");
                }
            }


        }
    }
    // }
}

const mutations = {
    setUserData(state, token) {
        state.userData = token;
    },
    setPageRedirect(state, token) {
        state.pageredirect = token;
    },
    setCandidateRedirect(state, token) {
        state.candidateRedirect = token;
    },
    setTrainingRedirect(state, token) {
        state.trainingRedirect = token;
    },
    setLoginData(state, token) {
        state.loginData = token;
    },
    setPanelId(state, token) {
        state.paneluuid = token;
    },
    setPreference(state, token) {
        state.preference = token;
    },
    setToken(state, token) {
        state.accessToken = token;
    },
    setRefreshToken(state, token) {
        state.refreshToken = token;
    },
    setRecruitment(state, data) {
        state.recruitment = data;
    },
    setAssessment(state, data) {
        state.assessment = data;
    },
    setCandidate(state, data) {
        state.candidate = data;
    },
    setTraining(state, data) {
        state.training = data;
    },
    setCollege(state, data) {
        state.collegeDashboard = data;
    },
    setLoginStatus(state, data) {
        state.loginStatus = data;
    },
    setUserName(state, data) {
        state.loggedUserName = data;
    },
    // setAssessmentWindow(state,data){
    //     state.assessmenWindow=data;
    // }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};